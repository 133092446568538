import { post } from '@/utils/request';

export const fetchMessageList = async data => {
  return await post('/appdealer/api/sms/list', data);
}

export const fetchMessageTemplate = async data => {
  return await post('/appdealer/api/sms/template/list', data);
}

export const fetchMessageDetail = async data => {
  return await post('/appdealer/api/sms/getDetail', data);
}

export const pushTestMessage = async data => {
  return await post('/appdealer/api/sms/sendTest', data);
}

export const sendMessage = async data => {
  return await post('/appdealer/api/sms/sendSms', data);
}

export const fetchSendSummary = async data => {
  return await post('/appdealer/api/sms/getSendSummary', data);
}

export const fetchLastSmsApp = async data => {
  return await post('/appdealer/api/sms/getLastSmsApp', data);
}